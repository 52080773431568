<template>
  <section class="page-section p-0 mt-3 pb-5">
  <div class="row">
    <div class="col-lg-12 m-auto">
      <div class="card">
        <h5
          class="card-header d-flex justify-content-between align-items-center"
        >
          <span>{{ $t("Page List") }}</span>
          <!-- <div class="btn-group" role="group" aria-label="Basic example">
            <button
              type="button"
              class="btn btn-sm btn-primary"
              @click="submit"
            >
              Save
            </button>
            <button type="button" class="btn btn-sm btn-light">Cancel</button>
          </div> -->
        </h5>
        <table class="card-body table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Order</th>
              <th scope="col">Language</th>
              <th scope="col">Section ID</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in filteredRecords" :key="row.id">
              <th scope="row">{{ row.id }}</th>
              <td>
                <span v-if="editView !== row.id">{{ row.order }}</span>
                <input
                  v-if="editView === row.id"
                  type="text"
                  class="form-control"
                  id="section-textpos"
                  v-model="form.order"
                />
              </td>
              <td>
                <span v-if="editView !== row.id">{{ row.lang }}</span>
                <select
                  v-if="editView === row.id"
                  class="form-control"
                  id="section-lang"
                  v-model="form.lang"
                >
                  <option value="en">en</option>
                  <option value="zh-HK">zh-HK</option>
                  <option value="zh-CN">zh-CN</option>
                  <!-- <option value="pure">Pure HTML</option> -->
                </select>
              </td>
              <td>
                <span v-if="editView !== row.id">{{ row.section }}</span>
                <input
                  v-if="editView === row.id"
                  type="text"
                  class="form-control"
                  id="section-textpos"
                  v-model="form.section"
                />
              </td>
              <td>
                <button
                  v-if="editView === false"
                  type="button"
                  class="btn btn-sm btn-light"
                  @click="() => toggleEditView(row.id)"
                >
                  Edit
                </button>
                <button
                  v-if="editView === row.id"
                  type="button"
                  class="btn btn-sm btn-primary"
                  @click="submit"
                >
                  Save
                </button>
                <button
                  v-if="editView === row.id"
                  type="button"
                  class="btn btn-sm btn-light"
                  @click="cancel"
                >
                  Cancel
                </button>
              </td>
            </tr>
            <!-- new -->
            <tr>
              <th scope="row">New</th>
              <td>
                <input
                  type="text"
                  class="form-control"
                  id="section-textpos"
                  v-model="newForm.order"
                />
              </td>
              <td>
                <select
                  class="form-control"
                  id="section-lang"
                  v-model="newForm.lang"
                >
                  <option value="en">en</option>
                  <option value="zh-HK">zh-HK</option>
                  <option value="zh-CN">zh-CN</option>
                  <!-- <option value="pure">Pure HTML</option> -->
                </select>
              </td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  id="section-textpos"
                  v-model="newForm.section"
                />
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-sm btn-primary"
                  @click="newSubmit"
                >
                  Submit
                </button>
                <button type="button" class="btn btn-sm btn-light">
                  Clear
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </section>
</template>

<script>
import Form from "vform";
import axios from "axios";

export default {
  middleware: "auth",

  components: {},

  metaInfo() {
    return { title: this.$t("homepage") };
  },

  data: () => ({
    editView: false,
    records: [],
    form: new Form({
      order: "",
      section: "",
    }),
    newForm: new Form({
      order: "",
      section: "",
    }),
  }),

  computed: {
      filteredRecords: function(){
          // console.log(this.records)
          // return Object.values(this.records)
          return this.records || []
      },
  },

  mounted() {
    console.log("mounted");
    this.getRecords().then((records) => {
      this.records = records;
    });
  },

  methods: {
    async getRecords() {
      const { data } = await axios.get(`${window.config.BASE_PATH}/api/record/homepageconfigs`)
      console.log(data)
      return data.records;
    },
    toggleEditView: function (id) {
      this.editView = id;
      if (id === false) return;
      let record = this.records.find((u) => u.id === id);
      console.log("Record selected", record);
      this.form.id = record.id;
      this.form.order = record.order;
      this.form.lang = record.lang;
      this.form.section = record.section;
    },
    submit: async function () {
      console.log("Save " + this.form.id, this.form);
      const { data } = await this.form.put(window.config.BASE_PATH + '/api/record/homepageconfig')
      if (data.success) {
        this.cancel()
        this.getRecords().then((records) => {
          this.records = records;
        });
      }
    },
    cancel: function () {
      this.editView = false;
      this.form.reset();
    },
    newSubmit: async function () {
      console.log("Save new");
      const { data } = await this.newForm.post(window.config.BASE_PATH + "/api/record/homepageconfig");
      // console.log(data)
      if (data.success) {
        this.getRecords().then((records) => {
          this.records = records;
        });
      }
      // window.location.refresh()
    },
    clear: function () {
      this.newForm.reset();
    },
  },
};
</script>
